// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Cultos(){
    return (
        <>
        <main class="container is-fluid has-background-black">
        <section class="hero is-black is-small">
            <div class="hero-body">
                <p class="title has-text-centered is-uppercase">
                Cultos
                </p>
            </div>
        </section>
        <section class="section">
            <header class="has-text-centered has-text-white m-4">
                "Nenhum membro de um corpo pode subsistir<br/> 
                sozinho, sem o resto do corpo."<br/>
                (1 Coríntios 12:21-22)
            </header>
        </section>
        <section class="section has-text-centered is-uppercase">
            <h1 class="title has-text-warning">Terça</h1>
            <h2 class="subtitle has-text-white">
                Culto Hope <br/>
                <time datetime="20:00">às 20h</time>
            </h2>
        </section>

        <section class="section has-text-centered is-uppercase">
            <h1 class="title has-text-warning">Quarta</h1>
            <h2 class="subtitle has-text-white">
                Culto de Oração <br/>
                <time datetime="12:00">12h00</time>
            </h2>
            <h2 class="subtitle has-text-white">
                Cura e Libertação <br/>
                <time datetime="14:00">14h</time> às <time datetime="17:00">17h</time>
            </h2>
            <h2 class="subtitle has-text-white">
                Culto Fé <br/>
                <time datetime="20:00">20h00</time>
            </h2>
        </section>
        
        <section class="section has-text-centered is-uppercase">
            <h1 class="title has-text-warning">Sábado</h1>
            <h2 class="subtitle has-text-white">
                Culto Rocket <br/>
                <time datetime="18:00">18h00</time>
            </h2>
            <h2 class="subtitle has-text-white">
                Culto Legacy <br/>
                <time datetime="20:30">20h30</time>
            </h2>
        </section>
        <section class="section has-text-centered is-uppercase">
            <h1 class="title has-text-warning">Domingo</h1>
            <h2 class="subtitle has-text-white">
                Culto de Celebração <br/>
                da Família <br/>
                <time datetie="10:30">10h30</time> e <time datetime="19:00">18h00</time>
            </h2>
            <h2 class="subtitle has-text-white">
                KIDS e Link <br/>
                <time datetime="10:30">10h30</time> e <time datetime="18:00">18h00</time>
            </h2>
        </section>
        <section class="section has-text-centered is-uppercase">
            <a href="https://www.youtube.com/@LagoinhaBraga" class="button is-danger is-rounded" title="Clique para assistir online">
                {/* <FontAwesomeIcon icon="fa-brands fa-youtube" />
                <span class="icon">
                    <i class="fa-brands fa-youtube"></i>
                </span> */}
                <span class="m-3">
                    Assistir Online
                </span>
            </a>
        </section>
        </main>
        </>
    )
}

export default Cultos